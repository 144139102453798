import React from 'react';
import { Heading } from '../components/UI/Heading';
import { Root } from '../components/Root';
import { Text } from '../components/UI/Text';
import styled from '@emotion/styled/macro';

export default () => {
  return (
    <Root includeFooter={true}>
      <Content>
        <Heading type="h1">Cookiebeleid</Heading>
        <Text mode="tag">Dit onderdeel is voor het laatst aangepast op 2 september 2020.</Text>

        <Heading type="h3">Wat zijn cookies?</Heading>
        <Text mode="normal">
          Cookies zijn kleine informatiebestandjes die een website op jouw hardware (bijvoorbeeld computer of smartphone) achterlaat. Op deze website worden verschillende cookies gebruikt. Onderstaand vindt je een overzicht van de cookies die deze website gebruikt.
        </Text>
        <br /><br />

        <Heading type="h3">Functionele cookies</Heading>
        <Text mode="normal">
          Dit zijn cookies die noodzakelijk zijn om de website naar behoren te laten functioneren. Deze cookies worden gebruikt om bepaalde gebruikers- en voorkeursinstellingen te bewaren, eventuele inloggegevens op te slaan, om het gebruik van de website te vergemakkelijken en te optimaliseren. Daarnaast worden deze cookies gebruikt voor het opsporen van misbruik van de website.
        </Text>
        <br /><br />

        <Heading type="h3">Social media cookies</Heading>
        <Text mode="normal">
          Informatie op de website kan worden gedeeld via social media. Daarvoor kan gebruik worden gemaakt van de op de website aanwezige social media buttons. Deze functioneren op basis van social media cookies die zijn geplaatst door de social media partijen (zoals bijvoorbeeld Facebook, Twitter en LinkedIn). Door op een social media button te klikken, word je doorgeleid naar de website van de desbetreffende social media partij. Voor het gebruik van de cookies van de social media partijen verwijzen wij naar de informatie hierover op de websites van deze social media partijen. Deze informatie kan regelmatig wijzigen zonder dat wij daar enige invloed op hebben.
        </Text>
        <br /><br />

        <Heading type="h3">Analytische cookies</Heading>
        <Text mode="normal">
          Deze cookies worden gebruikt om informatie te verzamelen over het gebruik van de website. Hiervoor wordt onder meer gebruik gemaakt van de software van Google Analytics. Gemeten wordt hoeveel bezoekers de website bezoeken, op welk tijdstip de website en –pagina’s worden bezocht, vanuit welke regio de internetgebruiker de website bezoekt, via welke andere website de bezoeker op de website is gekomen, welke pagina’s worden bezocht (hoe lang en hoe vaak) en in welke volgorde en/of er foutmeldingen zijn opgetreden. De verzamelde gegevens worden gebruikt om de inhoud van de website optimaal af te stemmen op de wensen en de behoeftes van de bezoekers.
        <br /><br />
        Je kunt met behulp van jouw browserinstellingen aangeven dat je niet (langer) wilt dat er cookies op jouw hardware worden geplaatst. In dat geval worden er alleen functionele cookies geplaatst nu deze nodig zijn om gebruik te kunnen maken van de website. Daarnaast wordt er in dat geval een zogenaamde no-follow cookie geplaatst om vast te kunnen leggen dat er alleen functionele cookies mogen worden geplaatst.
        <br /><br />
        Wanneer je ervoor kiest om met behulp van jouw browserinstellingen aan te geven dat je niet (langer) wilt dat er naast de functionele cookies ook andere cookies op jouw hardware worden geplaatst, dan kan dat de mogelijkheden en het functioneren van de website beïnvloeden.
        </Text>
        <br /><br />

        <Heading type="h3">Derden</Heading>
        <Text mode="normal">
          De informatie wordt niet met derden gedeeld. In enkele gevallen kan de informatie intern gedeeld worden. Onze werknemers zijn verplicht om de vertrouwelijkheid van uw gegevens te respecteren.
        </Text>
        <br /><br />

        <Heading type="h3">Veranderingen</Heading>
        <Text mode="normal">
          Deze privacyverklaring is afgestemd op het gebruik van en de mogelijkheden op deze site. Eventuele aanpassingen en/of veranderingen van deze site, kunnen leiden tot wijzigingen in deze privacyverklaring. Het is daarom raadzaam om regelmatig deze privacyverklaring te raadplegen.
        </Text>
        <br /><br />

        <Heading type="h3">Hoe kan u cookies verwijderen?</Heading>
        <Text mode="normal">
          Cookies kan je makkelijk verwijderen door volgende handleidingen te volgen:
        </Text>
        <br /><br />
        <a href={`https://support.apple.com/kb/ph19214?locale=en_US`}>
          <Text mode="normal">Apple safari</Text>
        </a>
        <br />
        <a href={`https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en`}>
          <Text mode="normal">Google Chrome</Text>
        </a>
        <br />
        <a href={`https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer`}>
          <Text mode="normal">Mozilla Firefox</Text>
        </a>
        <br />
        <a href={`https://support.microsoft.com/en-us/kb/260971`}>
          <Text mode="normal">Microsoft IE</Text>
        </a>
      </Content>
    </Root>
  )
}

const Content = styled.div`
  margin-bottom: 10rem;
`;